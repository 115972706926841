import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="34"
      fill="none"
      viewBox="0 0 35 34"
    >
      <path
        fill="#656C78"
        fillRule="evenodd"
        d="M21.488 25.705a16.769 16.769 0 01-3.787.428C10.134 26.133 4 21.178 4 15.067 4 8.954 10.134 4 17.701 4s13.702 4.955 13.702 11.066c0 2.785-1.273 5.329-3.374 7.274v5.9l-6.54-2.535zm10.04-1.95c2.065-2.36 3.375-5.352 3.375-8.688C34.903 6.36 26.466.5 17.7.5 8.937.5.5 6.36.5 15.066c0 8.707 8.437 14.567 17.201 14.567a20.42 20.42 0 003.475-.296l10.353 4.013v-9.595z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#656C78"
        d="M13 15.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM19 15.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM25 15.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
      ></path>
    </svg>
  );
}

export default React.memo(Icon);
