export const ADD_PEER = "ADD_PEER";
export const DELETE_PEER = "DELETE_PEER";
export const UPDATE_MY_UUID = "UPDATE_MY_UUID";
export const UPDATE_PEER_UUID = "UPDATE_PEER_UUID";
export const ADD_FILES = "ADD_FILES";
export const DELETE_FILES = "DELETE_FILES";
export const UPDATE_PROGRESS = "UPDATE_PROGRESS";
export const UPDATE_SELECTED_PEER = "UPDATE_SELECTED_PEER";
export const UPDATE_SELECTED_ROW = "UPDATE_SELECTED_ROW";
export const UPDATE_SENDER_ID = "UPDATE_SENDER_ID";
export const UPDATE_ORKA_THEME = "UPDATE_ORKA_THEME";
export const ADD_TOAST_MESSAGE = "ADD_TOAST_MESSAGE";
export const DELETE_TOAST_MESSAGE = "DELETE_TOAST_MESSAGE";
export const TOGGLE_MODAL_STATE = "TOGGLE_MODAL_STATE";
export const TOGGLE_GUIDE_STATE = "TOGGLE_GUIDE_STATE";
export const UPDATE_IS_MOBILE_WIDTH = "UPDATE_IS_MOBILE_WIDTH";
export const INCREASE_PROFILE_EDIT_EVENT_COUNTER =
    "INCREASE_PROFILE_EDIT_EVENT_COUNTER";

// db related
export const UPDATE_TABLE_USERS = "UPDATE_TABLE_USERS";
export const UPDATE_TABLE_SHARING_DATA = "UPDATE_TABLE_SHARING_DATA";
export const UPDATE_TABLE_NOTIFICATIONS = "UPDATE_TABLE_NOTIFICATIONS";
